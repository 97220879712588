import { Box, Center, SlideFade, Spinner } from "@chakra-ui/react";

const SlideFadeSpinner = ({
  isVisible,
  children,
  spinnerColor = "black",
}: {
  isVisible: boolean;
  children?: React.ReactNode;
  spinnerColor?: string;
}) => (
  <SlideFade in={isVisible}>
    <Box maxHeight={isVisible ? "none" : 0} overflow="hidden">
      {children ? (
        children
      ) : (
        <Center p="12">
          <Spinner color={spinnerColor} />
        </Center>
      )}
    </Box>
  </SlideFade>
);

export default SlideFadeSpinner;
